/* eslint-disable no-console */
import '../sass/index.scss';
// Lozad
import lozad from './util/lozad';
import {
	$body, lazyModule, $window, $document
} from './util/helpers';
import Polyfill from './polyfills';
import SiteCookie from './cookies';


window.isAppLoaded = window.isAppLoaded || false;

/**
 * App component
 * @class App
 */
class App {
	/**
	 * Initialise app
	 * @function init
	 */
	init() {
		// initialise polyfills
		Polyfill();
		// Load modules
		lazyModule();

		// lazy loads elements with default selector as '.lozad'
		const observer = lozad();
		observer.observe();

		$body.removeClass('body-hide');
		$document.on('ready', () => {
			$window.scrollTop(0);
			window.scrollTo(0, 0);
		});
		$window.on('unload', () => {
			$window.scrollTop(0);
		});

		// init Site cookies js
		this.SiteCookie = new SiteCookie();
		this.SiteCookie.init();
	}
}

/**
 * initialise app
 * @type function
 */
// eslint-disable-next-line func-names
export default (function () {
	// load CSS & thems
	if (!window.isAppLoaded) {
		// initialise app
		const ts = new App();
		ts.init();
		window.isAppLoaded = true;
	}
}());


const counters = document.querySelectorAll(".counter");
const options = {
  threshold: 1,
  rootMargin: "0px 0px -10% 0px",
};
const animateCounter = (targetElement) => {
  let currentCount = 0;
  const targetCount = parseInt(targetElement.dataset.target);
  const increment = Math.ceil(targetCount / 100);

  const counterInterval = setInterval(() => {
    if (currentCount < targetCount) {
      currentCount += increment;
      targetElement.textContent = currentCount;
    } else {
      targetElement.textContent = targetCount;
      clearInterval(counterInterval);
    }
  }, 30);
};
const handleIntersection = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      animateCounter(entry.target);
    }
  });
};
const observer = new IntersectionObserver(handleIntersection, options);
counters.forEach((counter) => {
  observer.observe(counter);
});
